import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { ToastrService } from "ngx-toastr";
import { HelperService } from "app/main/services/helper.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;
  private apiUrl: string = environment.apiUrl;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private _helper: HelperService
  ) {

    let d = localStorage.getItem('currentUser');
    let user = d ? (this._helper.decodeData(d) == "undefined" ? null : this._helper.decodeData(d)) : null;
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(user));
    this.currentUser = this.currentUserSubject.asObservable();

    // this.currentUserSubject = new BehaviorSubject<User>(
    //   JSON.parse(localStorage.getItem("currentUser"))
    // );
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Admin
    );
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Client
    );
  }

  /**
   *  get logged in User 'Company Id'
   */
  get getUserCompanyId() {
    if (this.currentUser && this.currentUserSubject.value) {
      return this.currentUserSubject.value.companyId;
    } else {
      return "";
    }
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${this.apiUrl}/auth/login`, { email, password })
      .pipe(
        map((user) => {
          if (user && user.success) {
            // login successful if there's a jwt token in the response
            if (
              user.data &&
              user.data.accessTokens &&
              user.data.accessTokens.token
            ) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              this.setUser(user.data);
            }
          }
          return user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("jwt");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("customThemeColors");
    // notify
    this.currentUserSubject.next(null);
  }

  /**
   * Set User object in local storage
   *
   */
  setUser(user: any) {
    user.role = Role.Admin;
    this.currentUserSubject.next(user);
    localStorage.setItem("jwt", user.accessTokens.token); 
    localStorage.setItem("refreshToken", user.accessTokens.refreshToken);
    localStorage.setItem(
      "currentUser",
      this._helper.encodeData(JSON.stringify(user))
    );
  }

  getUser(): User {
    return this.currentUserSubject.value
  }
}
