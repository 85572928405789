import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { environment } from 'environments/environment';

@Directive({
  selector: 'img[appImageErrorHandler]'
})
export class ImgErrorHandlerDirective {

  @Input() defaultImage: string = environment.defaultBrokenUserImage; // Default image URL

  constructor(private el: ElementRef) {}

  @HostListener('error') onError() {
    this.el.nativeElement.src = this.defaultImage; // Replace broken image with default image
  }
}
