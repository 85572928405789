import { AuthenticatedResponse } from "app/main/interfaces/authenticated-response";
import { Role } from "./role";

// export class User {
//   id: number;
//   email: string;
//   password: string;
//   firstName: string;
//   lastName: string;
//   avatar: string;
//   role: Role;
//   token?: string;
// }

export class User {
  id?: number; //used in theme
  firstName?: string; //used in theme
  lastName?: string; //used in theme
  password?: string; //used in theme
  avatar?: string; //used in theme
  userId?: string;
  companyId?: string;
  email?: string;
  name?: string;
  companyName?: string;
  companyLogo?: string;
  accessTokens?: AuthenticatedResponse;
  role?: Role;
}
