import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect/core-ripple-effect.directive';
import { ImgErrorHandlerDirective } from './core-img-error-handler.directive';

@NgModule({
  declarations: [RippleEffectDirective, FeatherIconDirective, ImgErrorHandlerDirective],
  exports: [RippleEffectDirective, FeatherIconDirective,ImgErrorHandlerDirective]
})
export class CoreDirectivesModule {}
