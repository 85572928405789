import { CoreMenu } from "@core/types";

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: "dashboard",
    title: "Dashboard",
    translate: "MENU.DASHBOARD.COLLAPSIBLE",
    type: "item",
    url: 'dashboard/ecommerce',
    // role: ['Admin'], //? To hide collapsible based on user role
    icon: "home"
  },
  // Pages
  {
    id: "order",
    title: "Orders",
    type: "item",
    icon: "truck",
    url: "pages/order/list"
  },
  {
    id: "customers",
    title: "Customers",
    type: "collapsible",
    icon: "users",
    children: [
      {
        id: "customers",
        title: "Customers",
        type: "item",
        icon: "circle",
        url: "pages/customer/list",
      },
      {
        id: "invoice&CreditNotes",
        title: "Invoice & Credit Notes",
        type: "item",
        icon: "circle",
        url: "pages/invoicenotes/list",
      }
    ]
  },
  {
    id: "product",
    title: "Product",
    type: "collapsible",
    icon: "package",
    children: [
      {
        id: "product",
        title: "Product Records",
        type: "item",
        icon: "circle",
        url: "pages/product/records",
      },
      {
        id: "product",
        title: "Pallet Records",
        type: "item",
        icon: "circle",
        url: "pages/product/pallet",
      },
    ],
  },
  {
    id: "Purchases",
    title: "Purchases",
    type: "collapsible",
    icon: "dollar-sign",
    children: [
      {
        id: "Purchaseorders",
        title: "Purchase Orders",
        type: "item",
        icon: "circle",
        url: "pages/purchase-supplier/po-list",
      },
      {
        id: "book-in-stock",
        title: "Book In Stock",
        type: "item",
        icon: "circle",
        url: "pages/purchase-supplier/book-in-stock",
      },
      {
        id: "adjust-transfer-stock",
        title: "Adjust & Transfer Stock",
        type: "item",
        icon: "circle",
        url: "pages/purchase-supplier/adjust-transfer-stock",
      },
      {
        id: "serial-search",
        title: "Serial# Search",
        type: "item",
        icon: "circle",
        url: "pages/purchase-supplier/serial-search",
      },
    ],
  },
  {
    id: "Suppliers",
    title: "Suppliers",
    type: "collapsible",
    icon: "database",
    children: [ 
      {
      id: "Supplier",
      title: "Suppliers",
      type: "item",
      icon: "circle",
      url: "pages/purchase-supplier/supplier-list",
    },
    {
      id: 'supplier-invoice',
      title: 'Supplier Invoices',
      type: 'item',
      icon: 'circle',
      url: 'pages/purchase-supplier/supplier-invoice-list'
    },
  ]
  },
  {
    id: "accounts",
    title: "Accounts",
    type: "collapsible",
    icon: "book",
    children: [
      {
        id: "Cash&Bank",
        title: "Cash & Bank",
        type: "collapsible",
        icon: "circle",
        children: [
          {
            id: "Overview",
            title: "Overview",
            type: "item",
            icon: "circle",
            url: "pages/accounts/overview",
          },
          {
            id: "Enter Transaction",
            title: "Enter Transaction",
            type: "item",
            icon: "circle",
            url: "pages/accounts/enter-transaction",
          },
          {
            id: "Post Transaction",
            title: "Post Transaction",
            type: "item",
            icon: "circle",
            url: "pages/accounts/post-transaction",
          },
          {
            id: "Allocate Transaction",
            title: "Allocate Transaction",
            type: "item",
            icon: "circle",
            url: "pages/accounts/allocate-transaction",
          },
          {
            id: "Reconcile Bank",
            title: "Reconcile Bank",
            type: "item",
            icon: "circle",
            url: "pages/accounts/reconcile-bank",
          },
        ]
      },
      {
        id: "Reports",
        title: "Reports",
        type: "collapsible",
        icon: "circle",
        children: [
          {
            id: "PL Report",
            title: "PL Report",
            type: "item",
            icon: "circle",
            url: "pages/accounts/pl-report",
          },
          {
            id: "Balance Sheet",
            title: "Balance-Sheet",
            type: "item",
            icon: "circle",
            url: "pages/accounts/balance-sheet",
          },
          {
            id: "Trial Balance",
            title: "Trial Balance",
            type: "item",
            icon: "circle",
            url: "pages/accounts/trial-balance",
          },
          {
            id: "General Ledger",
            title: "General Ledger",
            type: "item",
            icon: "circle",
            url: "pages/accounts/general-ledger",
          },
          {
            id: "Stock Reports",
            title: "Stock Reports",
            type: "collapsible",
            icon: "layers",
            children: [
              {
                id: "Stock Report",
                title: "Stock Report",
                type: "item",
                icon: "circle",
                url: "pages/accounts/stock-report",
              },
              {
                id: "Goods Shipped Out",
                title: "Goods Shipped Out",
                type: "item",
                icon: "circle",
                url: "pages/accounts/goods-shipped-out",
              },
              {
                id: "Goods Booked In",
                title: "Goods Booked In",
                type: "item",
                icon: "circle",
                url: "pages/accounts/goods-booked-in",
              },
              {
                id: "Stock Adjustment Report",
                title: "Stock Adjustment Report",
                type: "item",
                icon: "circle",
                url: "pages/accounts/stock-adjustment-report",
              },
              {
                id: "All Stock Movements",
                title: "All Stock Movements",
                type: "item",
                icon: "circle",
                url: "pages/accounts/all-stock-moments",
              },
            ]
          },  
          {
            id: "Sale Ledger",
            title: "Sale Ledger",
            type: "item",
            icon: "circle",
            url: "pages/accounts/sales-ledger",
          },
          {
            id: "Purchase Ledger",
            title: "Purchase Ledger",
            type: "item",
            icon: "circle",
            url: "pages/accounts/purchase-ledger",
          },
          {
            id: "GRNI Report",
            title: "GRNI Report",
            type: "item",
            icon: "circle",
            url: "pages/accounts/grni-report",
          },
          {
            id: "Supplier Invoices InQuery Report",
            title: "Supplier Invoices InQuery Report",
            type: "item",
            icon: "circle",
            url: "pages/accounts/supplier-invoice-inquery-report",
          },
        ]
      },
      {
        id: "Journals",
        title: "Journals",
        type: "collapsible",
        icon: "circle",
        children:[
          {
            id: "list&searchjournals",
            title: "List & Search Journals",
            type: "item",
            icon: "circle",
            url: "pages/accounts/journal-list",
          },
          {
            id: "Addjournals",
            title: "Add Journals",
            type: "item",
            icon: "circle",
            url: "pages/accounts/add-journals/null",
          },
        ]
        // url: "pages/accounts/cash-and-bank/overview",
      },
      {
        id: "Retranslation",
        title: "Retranslation",
        type: "item",
        icon: "circle",
        url: "pages/accounts/retranslation",
      }
      
    ]
  },
  {
    id: "settting&integrations",
    title: "Settings & Integrations",
    type: "collapsible",
    icon: "settings",
    children: [
      {
        id: "ManageIntegrations",
        title: "Manage Integrations",
        type: "item",
        icon: "circle",
        url: "pages/setting-integration/manage-integration",
      },
      {
        id: "Business Settings",
        title: "Business Settings",
        type: "item",
        icon: "circle",
        url: "pages/setting-integration/business-settings",
      },
      {
        id: "Historic Uploads",
        title: "Historic Uploads",
        type: "item",
        icon: "circle",
        url: "pages/setting-integration/historic-uploads",
      },
      {
        id: "Uploads Files",
        title: "Uploads Files",
        type: "item",
        icon: "circle",
        url: "pages/setting-integration/upload-files",
      },{
        id: "Account&reporting",
        title: "Accounting & Reporting Periods",
        type: "collapsible",
        icon: "circle",
        // url: "pages/setting-integration/manage-integration",
    children: [
      {
        id: "create-accounting",
        title: "Create Accounting Periods",
        type: "item",
        icon: "circle",
        url: "pages/setting-integration/create-accounting-periods",
      },
      {
        id: "view-accounting",
        title: "View Accounting Periods",
        type: "item",
        icon: "circle",
        url: "pages/setting-integration/view-accounting-periods",
      },

    ]
      },
      {
        id: "forex-history",
        title: "Forex History",
        type: "item",
        icon: "circle",
        url: "pages/setting-integration/forex-history",
      },
      {
        id: "PaymentMethod",
        title: "Payment Method",
        type: "item",
        icon: "circle",
        url: "pages/setting-integration/payment-method",
      },
      {
        id: "StoreSetting",
        title: "Store Setting",
        type: "item",
        icon: "circle",
        url: "pages/setting-integration/store-setting",
      },
      {
        id: "email$commssetting",
        title: "Email & Comms Settings",
        type: "item",
        icon: "circle",
        url: "pages/setting-integration/email-Comms-Settings",
      },

    ]
  },
  {
    id: "security",
    title: "Security",
    type: "collapsible",
    icon: "globe",
    children: [
      {
        id: "permissions",
        title: "Permissions",
        type: "item",
        icon: "circle",
        url: "pages/security/permission",
      },
      {
        id: "roles",
        title: "Roles",
        type: "item",
        icon: "circle",
        url: "pages/security/roles",
      },
      {
        id: "users",
        title: "Users",
        type: "item",
        icon: "circle",
        url: "pages/security/users",
      },
      {
        id: "apiAccess",
        title: "Api Access",
        type: "item",
        icon: "circle",
        url: "pages/security/api-access",
      },
    ]
  },
  {
    id: "services",
    title: "Services", 
    type: "collapsible",
    icon: "grid",
    children: [
      {
        id: "providers",
        title: "Providers",
        type: "item",
        icon: "circle",
        url: "pages/services/providers",
      },
      {
        id: "contracts",
        title: "Contracts",
        type: "item",
        icon: "circle",
        url: "pages/services/contracts",
      }
    ]
  }
]
  

  
  