import { Injectable, NgZone } from "@angular/core";
import Swal from "sweetalert2";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { BehaviorSubject, Observable } from "rxjs";
import { NgxUiLoaderService, NgxUiLoaderConfig, SPINNER } from "ngx-ui-loader";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  //loading:boolean = false;
  @BlockUI() blockUI: NgBlockUI;
  private loading: BehaviorSubject<boolean> = null;
  public currentLoading: Observable<boolean>;

  constructor(private zone: NgZone, private ngxService: NgxUiLoaderService) {
    // this.loading.next(false);
    this.loading = new BehaviorSubject<boolean>(false);
    this.currentLoading = this.loading.asObservable();
  }

  //#region Client side encryption functions
  encodeData(data): any {
    return window.btoa(data);
  }

  decodeData(data): any {
    if (data) return window.atob(data);
    else return null;
  }
  //#endregion Client side encryption functions

  startPageLoader() {
    // this.ngxService.start();
    this.blockUI.start();
   }
   stopPageLoader() {
     //this.ngxService.stop();
     this.blockUI.stop();
   }

  printHTML(data, heading) {
    var jdata = JSON.stringify(data);
    var params = [
      "height=" + screen.height,
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    var mywindow = window.open("", heading, params);
    mywindow.document.write("<html><head><title>" + heading + "</title>");

    mywindow.document.write("</head><body>");
    mywindow.document.write(data);
    mywindow.document.write("</body></html>");
    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    mywindow.close();
    return true;
  }

  replaceSpecialCharacters(value) {
    return value.replace(/[/\\?%*:|"<>]/g, "-");
  }

  alertMessage(title, html, type) {
    return Swal.fire(title, html, type);
  }
  companyInfoAlert(_title = "Confirm", _html) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: _title,
        html: _html,
        showCancelButton: true,
        confirmButtonText: "Go to Settings",
        cancelButtonText: "Cancel",
        cancelButtonColor: "#e96429",
        confirmButtonColor: "#aaa",
      }).then((result) => {
        if (result.value) {
          resolve(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false);
        }
      });
    });
  }
  confirm(_title = "Confirm", _html) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: _title,
        html: _html,
        //type: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          resolve(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false);
        }
      });
    });
  }

  confirmContactModalClose(_title = "Confirm", _html) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: _title,
        html: _html,
        //type: "question",
        showCancelButton: true,
        confirmButtonText: "Quit",
        cancelButtonText: "Continue",
        cancelButtonColor: "#e96429",
        confirmButtonColor: "#aaa",
      }).then((result) => {
        if (result.value) {
          resolve(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false);
        }
      });
    });
  }

   createDateFromNgbDate(ngbDate: NgbDate): Date {
    const date: Date = new Date(Date.UTC(ngbDate.year, ngbDate.month, ngbDate.day));
    return date;
  }

  calcMonthDiff(from, to): number {
    const fromDate: Date = this.createDateFromNgbDate(from);
    const toDate: Date = this.createDateFromNgbDate(to);
    const daysDiff = Math.round(
      Math.abs(<any>fromDate - <any>toDate) / (1000 * 60 * 60 * 24) / 30
    );
    return daysDiff;
  }

  calcDiff(from, to) {
    const fromDate: Date = new Date(from);
    const toDate: Date = new Date(to);
    const daysDiff = Math.floor(
      Math.abs(<any>fromDate - <any>toDate) / (1000 * 60 * 60 * 24) / 30
    );

    var diff = Math.floor(Math.abs(toDate.getTime() - fromDate.getTime()));
    var secs = Math.floor(diff / 1000);
    var mins = Math.floor(secs / 60);
    var hours = Math.floor(mins / 60);
    var days = Math.floor(hours / 24);
    var months = Math.floor(days / 31);
    var years = Math.floor(months / 12);
    months = Math.floor(months % 12);
    days = Math.floor(days % 31);
    hours = Math.floor(hours % 24);
    mins = Math.floor(mins % 60);
    secs = Math.floor(secs % 60);
    var message = "";
    if (years > 0) {
      message += years + (years > 1 ? "years" : "year");
    } else if (months > 0) {
      message += months + (months > 1 ? "months" : "month");
    } else if (days > 0) {
      message += days + (days > 1 ? "days" : "day");
    } else if (hours > 0) {
      message += hours > 0 ? hours + (hours > 1 ? "hours" : "hour") : "";
    } else if (mins > 0) {
      message += mins > 0 ? mins + (mins > 1 ? "mins" : "min") : "";
    } else if (secs > 0) {
      message += secs + "secs";
    }
    return message;
  }

  getResponseJson(s) {
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(s, "text/xml");
    var result =
      xmlDoc.getElementsByTagName("string")[0].childNodes[0].nodeValue;
    return JSON.parse(result);
  }

  ConvertHrsToHrsAndMin(hours) {
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rminutes > 0) {
      return (rhours > 0 ? rhours + " hrs " : "") + rminutes + " mins";
    } else {
      return rhours + " hrs ";
    }
  }

  GetTotalHoursFromNow(date) {
    // get total seconds between the times
    var delta = Math.abs(+new Date(date) - +new Date()) / 1000;

    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    var seconds = Math.floor(delta % 60); // in theory the modulus is not required

    return (
      (days > 0 ? days + " day " : "") +
      (hours > 0 ? hours + " hrs " : "") +
      (minutes > 0 ? minutes + " mins " : "") +
      (seconds > 0 ? seconds + " secs " : "")
    );
  }
}
