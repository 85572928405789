import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";

import { AuthenticationService } from "app/auth/service";
import { AuthenticatedResponse } from "app/main/interfaces/authenticated-response";
import { environment } from "environments/environment";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private jwtHelper: JwtHelperService,
    private http: HttpClient,
    private _authenticationService: AuthenticationService
  ) {}

  private apiUrl: string = environment.apiUrl;

  // canActivate
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;

    if (currentUser) {
      const token = localStorage.getItem("jwt");
      if (token && !this.jwtHelper.isTokenExpired(token)) {
        //console.log(this.jwtHelper.decodeToken(token));
        return true;
      }
      const isRefreshSuccess = await this.tryRefreshingTokens(token);
      if (!isRefreshSuccess) {
        this._router.navigate(["/pages/authentication/login"], {
          queryParams: { returnUrl: state.url },
        });
      }
      return isRefreshSuccess;
    } else {
      // not logged in so redirect to login page with the return url
      this._router.navigate(["/pages/authentication/login"], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }

    // if (currentUser) {
    //   // check if route is restricted by role
    //   if (
    //     route.data.roles &&
    //     route.data.roles.indexOf(currentUser.role) === -1
    //   ) {
    //     // role not authorised so redirect to not-authorized page
    //     this._router.navigate(["/pages/miscellaneous/not-authorized"]);
    //     return false;
    //   }

    //   // authorised so return true
    //   return true;
    // }

    // // not logged in so redirect to login page with the return url
    // this._router.navigate(["/pages/authentication/login"], {
    //   queryParams: { returnUrl: state.url },
    // });
    // return false;
  
  }

  private async tryRefreshingTokens(token: string): Promise<boolean> {
    const refreshToken: string = localStorage.getItem("refreshToken");
    if (!token || !refreshToken) {
      return false;
    }

    const credentials = JSON.stringify({
      Token: token,
      RefreshToken: refreshToken,
    });
    let isRefreshSuccess: boolean = false;

    const refreshRes = await new Promise<any>((resolve, reject) => {
      this.http
        .post<any>(`${this.apiUrl}/auth/refresh`, credentials, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        })
        .subscribe({
          next: (res: any) => resolve(res),
          error: (_) => {
            reject;
            isRefreshSuccess = false;
          },
        });
    });

    if (
      refreshRes && // Check if refreshRes is defined
      refreshRes.success && // Check if refreshRes has a 'success' property
      refreshRes.data && // Check if refreshRes.data is defined
      refreshRes.data.token && // Check if refreshRes.data.token is defined
      refreshRes.data.refreshToken // Check if refreshRes.data.refreshToken is defined
    ) {
      try {
        // Set items in localStorage
        localStorage.setItem("jwt", refreshRes.data.token);
        localStorage.setItem("refreshToken", refreshRes.data.refreshToken);
        isRefreshSuccess = true;
      } catch (error) {
        // Handle any potential errors when setting items in localStorage
        console.error("Error setting items in localStorage:", error);
      }
    }

    return isRefreshSuccess;
  }
}
